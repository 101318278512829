<template>
  <div class="login" v-if="isLogin">
    <v-card :loading="isLoading" class="mx-auto" max-width="700">
      <v-card-title>Log in</v-card-title>
      <v-card-text>
        <errorAlert :error="error" v-show="error" />

        <div v-if="isTimedOut">
          <v-alert type="info" transition="scale-transition">
            You have been logged out due to inactivity
          </v-alert>
        </div>
        <div class="mx-auto max-500">
          <v-form ref="form" v-model="valid">
            <v-text-field
              @keyup.enter="validate"
              label="Username"
              v-model="username"
              append-icon="mdi-account"
              :disabled="isLoading"
              autofocus
              :rules="[requiredRule]"
            />
            <v-text-field
              @keyup.enter="validate"
              label="Password"
              v-model="password"
              type="password"
              append-icon="mdi-lock"
              :disabled="isLoading"
              :rules="[requiredRule]"
            />
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions class="mx-auto max-500">
        <v-btn
          class="mx-auto login-button"
          :block="!isLoading"
          color="#B10021"
          @click="validate"
          :icon="isLoading"
          :disabled="!valid || isLoading || isLoggingOut"
        >
          <v-progress-circular
            v-if="isLoading || isLoggingOut"
            indeterminate
            color="primary"
          />
          <v-icon v-else left>mdi-arrow-right-bold-circle-outline</v-icon>
          <span v-if="!isLoading && !isLoggingOut">Login</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <multiFactorVerificationDialog
      v-if="multiFactorLoginRequired"
      :show="showMultiVerificationDialog"
      @cancel="showMultiVerificationDialog = false"
      @multiFactorVerificationReceived="onMultiFactorVerificationReceived"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from "vuex";
import ErrorAlert from "@/components/errorAlert.vue";
import modulePageMixin from "@/utils/mixins";
import multiFactorVerificationDialog from "./multiFactorVerificationDialog.vue";

export default {
  components: {
    ErrorAlert,
    multiFactorVerificationDialog,
  },
  props: {
    ssoToken: { type: String, required: false, default: "" },
  },
  mixins: [modulePageMixin],
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
      valid: false,
      requiredRule: (v) => !!v || "This field is required",
      isTimedOut: false,
      showMultiVerificationDialog: false,
    };
  },
  computed: {
    ...mapState("auth", ["error", "isLoggedIn", "isLoggingOut"]),
    ...mapGetters("auth", ["isFullyLoggedIn", "getLoginRedirect"]),
    ...mapGetters("appConfig", ["getApplicationConfig"]),
    multiFactorLoginRequired() {
      return this.getApplicationConfig("requireMultiFactorLogin");
    },
    isLogin() {
      return this.getUrlSegment(2) === "login";
    },
    isLogout() {
      return this.getUrlSegment(2) === "logout";
    },
  },
  methods: {
    ...mapActions("auth", ["login", "loginSSO", "logout"]),
    ...mapMutations("auth", ["setLoginRedirect"]),
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.submit();
      }
    },
    onMultiFactorVerificationReceived(valid) {
      if (valid) {
        this.redirect();
      }
    },
    async submit() {
      this.isTimedOut = false;
      this.isLoading = true;
      await this.login({
        username: this.username,
        password: this.password,
      });

      this.isLoading = false;
      if (this.isLoggedIn) {
        if (this.multiFactorLoginRequired) {
          this.showMultiVerificationDialog = true;

          return;
        }

        await this.redirect();
      }
    },
    async submitSsoToken() {
      this.isLoading = true;
      await this.loginSSO({
        ssoToken: this.ssoToken,
      });
      this.isLoading = false;
      if (this.isFullyLoggedIn) {
        await this.$router.push("/");
      }
    },
    async redirect() {
      await this.$nextTick();
      const redirect = this.getLoginRedirect;
      if (redirect !== null) {
        this.setLoginRedirect(null);
        await this.$router.push(redirect);
        return;
      }
      await this.$router.push("/");
    },
  },
  async mounted() {
    if (this.isLogout) {
      await this.logout();
      // trigger a hard nav to the login page, that way there is no state contamination
      window.location = "/login";
      return;
    }

    if (this.ssoToken && this.getApplicationConfig("acceptSSOToken")) {
      await this.submitSsoToken();
    }
    this.isTimedOut = sessionStorage.getItem("isTimedOut") === "true";
    sessionStorage.removeItem("isTimedOut");
  },
  page: {
    title: "Login",
    meta: [{ name: "description", content: "The Login page." }],
  },
};
</script>

<style lang="scss" scoped>
.max-500 {
  max-width: 500px;
}
.login-button {
  color: white;
}
</style>
