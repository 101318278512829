<template>
  <div class="multiFactorVerificationDialog">
    <v-dialog v-model="show" persistent max-width="600">
      <v-card>
        <v-container>
          <v-form ref="form" class="pa0 pl-2 ma-0" style="padding-bottom: 20px">
            <v-row class="text-center pa-0 pl-2 ma-0">
              <v-col cols="12" class="pa-0 pl-2 ma-0">
                <v-img :src="assetLogo" class="mt-4 pa-0" contain height="50" />
              </v-col>
              <v-col class="pa-0 pl-2 ma-0">
                <p class="dialog-title">Two-Factor Authentication</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 pl-2 ma-0">
                <p>
                  To keep your information secure, we need to confirm your
                  identity with an additional step.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 pl-2 ma-0">
                <p>Please choose how you'd like to receive your code.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pa-0 pl-2 ma-0">
                <v-radio-group
                  :mandatory="true"
                  v-model="emailOrPhone"
                  class="ma-0 pa-0 pl-2"
                >
                  <v-radio
                    v-if="userPhone"
                    :label="'Text to ' + obfuscatePhone"
                    value="userPhone"
                    class="select-delivery"
                  />
                  <v-radio
                    v-if="userPersonalEmail"
                    :label="'Email to ' + obfuscatePersonalEmail"
                    value="userPersonalEmail"
                    class="select-delivery"
                  />
                  <v-radio
                    v-if="userWorkEmail"
                    :label="'Email to ' + obfuscateWorkEmail"
                    value="userWorkEmail"
                    class="select-delivery"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="pa-0 pl-2 ma-0">
                <v-btn
                  color="primary"
                  outlined
                  class="resend-button ma-2 pa-0 pl-2"
                  @click="requestCode"
                  :loading="isLoadingMultiFactor"
                  :disabled="
                    isLoadingMultiFactor || countdown !== 0 || error !== null
                  "
                >
                  {{ sent ? "Resend Code" : "Send Code" }}
                </v-btn></v-col
              ></v-row
            >
          </v-form>

          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-card :loading="isLoadingMultiFactor">
                  <v-alert
                    v-if="isFallback || multiFactorVerificationError"
                    type="error"
                    transition="scale-transition"
                  >
                    <span v-if="multiFactorVerificationError">{{
                      multiFactorVerificationError.detail
                    }}</span>
                    <span v-if="isFallback"> {{ isFallback }}</span>
                  </v-alert>
                  <v-alert
                    v-if="countdown !== 0 && error === null"
                    type="info"
                    transition="scale-transition"
                  >
                    The verification code was sent again. Wait
                    {{ timeRemaining }} min to resend again
                  </v-alert>
                  <v-card-title>
                    <v-icon
                      v-if="sendType === 'sms'"
                      style="font-size: 1.75rem"
                      class="mb-4 pa-0 pl-2"
                    >mdi-cellphone</v-icon
                    >
                    <p v-if="sendType === 'sms'" class="font-weight-light">
                      We sent a code to your phone {{ obfuscatePhone }}
                    </p>
                    <v-icon
                      v-if="sendType === 'email'"
                      style="font-size: 2rem"
                      class="mb-4 pa-0 pl-2"
                    >mdi-email</v-icon
                    >
                    <p v-if="sendType === 'email'" class="font-weight-light">
                      We sent a code to the email you've chosen.
                    </p>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12" class="pa-0 pl-2 ma-0">
                        <v-text-field
                          v-model="verificationCode"
                          :rules="[required, isNumber, isCorrectLength]"
                          dense
                          label="Please, Enter Verification Code"
                          v-mask="'######'"
                          @keydown="codeEvent"
                          @keyup.enter.native="submit"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="text-center">
                      <v-col cols="12" class="pb-1">
                        <v-btn
                          class="mx-auto white--text"
                          block
                          color="#B10021"
                          :disabled="!sent || isLoadingMultiFactor"
                          @click="submit"
                        >
                          Verify Code
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="text-center">
                      <v-col cols="12" class="pb-1">
                        <v-btn block class="mx-auto" @click="cancel">
                          Cancel
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { createDynamicAsset } from "@/utils/dynamicAssets";
import { getEnv } from "@/utils/util";
import validators from "@/utils/validators";
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  data() {
    return {
      verificationCode: "",
      valid: false,
      requestedCode: false,
      timeRemaining: 0,
      countdown: 0,
      emailOrPhone: "",
      sendTo: null,
      sendType: null,
      sent: false,
      countdownInterval: null,
    };
  },
  computed: {
    ...mapState("auth", [
      "error",
      "multiFactorVerificationError",
      "isLoadingMultiFactor",
      "multiFactorApiResponse",
      "userInfo",
    ]),
    assetLogo() {
      return createDynamicAsset("logo.png");
    },
    isFallback() {
      return this.multiFactorApiResponse.isFallback
        ? "There was an issue sending the code to your phone number."
        : false;
    },
    userPhone() {
      if (this.userInfo !== null) {
        return this.userInfo.telephone.trim();
      }
      return false;
    },
    obfuscatePhone() {
      if (this.userInfo !== null) {
        return this.userInfo.telephone.trim().replace(/\d(?=.*\d{4})/g, "*");
      }
      return false;
    },
    userPersonalEmail() {
      if (this.userInfo !== null) {
        return this.userInfo.emailAddress2.trim().toLowerCase();
      }
      return false;
    },
    obfuscatePersonalEmail() {
      if (this.userInfo !== null) {
        return this.userInfo.emailAddress2
          .trim()
          .toLowerCase()
          .replace(/(.{4})(.*)(?=@)/, (gp1, gp2, gp3) => {
            for (let i = 0; i < gp3.length; i++) {
              gp2 += "*";
            }
            return gp2;
          });
      }
      return false;
    },
    userWorkEmail() {
      if (this.userInfo !== null) {
        return this.userInfo.email.trim().toLowerCase();
      }
      return false;
    },
    obfuscateWorkEmail() {
      if (this.userInfo !== null) {
        return this.userInfo.email
          .trim()
          .toLowerCase()
          .replace(/(.{4})(.*)(?=@)/, (gp1, gp2, gp3) => {
            for (let i = 0; i < gp3.length; i++) {
              gp2 += "*";
            }
            return gp2;
          });
      }
      return false;
    },
  },
  props: {
    show: { type: Boolean, required: true },
  },
  watch: {
    emailOrPhone(value) {
      if (value) {
        this.saveToLocalStorage(value);
      }
    },
    userInfo(value) {
      if (value !== null) {
        const ESS_2FA_SELECT = localStorage.getItem(
          `ESS_2FA_SELECT_${this.userInfo.userId.trim()}`,
        );

        if (ESS_2FA_SELECT !== null) {
          this.emailOrPhone = JSON.parse(ESS_2FA_SELECT).selection;
        }
      }
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapMutations("auth", ["setDefault"]),
    async codeEvent(e) {
      // if the pressed key is not a number or a dash
      if (Number.isNaN(e.key)) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    async requestCode() {
      if (this.emailOrPhone === "userPhone") {
        this.sendTo = this.userPhone;
        this.sendType = "sms";
      } else if (this.emailOrPhone === "userPersonalEmail") {
        this.sendTo = this.userPersonalEmail;
        this.sendType = "email";
      } else {
        this.sendTo = this.userWorkEmail;
        this.sendType = "email";
      }

      await this.$store.dispatch("auth/requestMultiFactorVerificationCode", {
        sendto: this.sendTo,
        sendtype: this.sendType,
      });

      // on resend:
      if (this.error === null && this.sent) {
        this.requestedCode = true;
        this.countdown = getEnv("VUE_APP_MULTIFACTOR_RESEND_CODE_TIMER");
        this.countdownInterval = setInterval(() => {
          this.countdown -= 1000;
          // format to m:ss
          this.timeRemaining = new Date(this.countdown)
            .toISOString()
            .slice(14, 19)
            .replace(/^0+/, "");
        }, 1000);
        setTimeout(() => {
          clearInterval(this.countdownInterval);
        }, getEnv("VUE_APP_MULTIFACTOR_RESEND_CODE_TIMER"));
      }

      this.sent = true;
    },
    required(val) {
      return validators.required(val) || "This field is required";
    },
    isNumber(val) {
      return !Number.isNaN(val) || "Value must be a number";
    },
    isCorrectLength(val) {
      return val.length === 6 || "The code must have 6 characters";
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.value) {
        await this.$store.dispatch(
          "auth/submitMultiFactorVerificationCode",
          this.verificationCode.trim(),
        );
        if (!this.multiFactorVerificationError) {
          this.$emit("multiFactorVerificationReceived", true);
        }
      }
    },
    async cancel() {
      this.$emit("cancel");
      this.verificationCode = "";
      this.valid = false;
      this.requestedCode = false;
      this.timeRemaining = 0;
      this.countdown = 0;
      this.emailOrPhone = "";
      this.sendTo = null;
      this.sendType = null;
      this.sent = false;
      clearInterval(this.countdownInterval);

      await this.logout();
    },
    saveToLocalStorage(value) {
      localStorage.setItem(
        `ESS_2FA_SELECT_${this.userInfo.userId.trim()}`,
        JSON.stringify({
          selection: value,
        }),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-title {
  color: #616161;
  font-size: 1.2em;
  padding: 0;
  margin: 0;
}
.resend-button {
  width: 175px;
  padding: 0;
  margin: 0;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
.v-label {
  font-size: 0.9rem;
}
.v-icon.v-icon {
  font-size: 20px;
}
</style>
